import React from "react";
import { Link } from "react-router-dom";
import "../Connect/Downloads/Brochure.scss";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { HOMEO_EVENT_GET } from "../../Utils/InfoUploadingConst";

function AyurvedaEvents() {
  const [data, setData] = useState([]);

  const navigate = useNavigate();
  function customSortByDate(item1, item2) {
    return item2.date.localeCompare(item1.date);
  }

  const getData = async () => {
    const config = {
      method: "get",
      url: HOMEO_EVENT_GET,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        const EventsData = res.data.data.filter(
          (element) =>
            element.college_id == "1111001" && element.status === "ACTIVE"
        );

        EventsData.sort(customSortByDate);

        setData(EventsData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const next = async (id, data1, images) => {
    console.log("error here");
    navigate(`/ayurveda/eventsDetails/${id}`, {
      state: {
        images: images,
        data: data1,
      },
    });
  };

  return (
    <div className="Events ">
      <div class="page-banner-area bg-3">
        <div class="container">
          <div class="page-banner-content">
            <h1>Events </h1>
            <ul>
              <li>
                {" "}
                <Link to={"./../"}> Home </Link>
              </li>

              <li>Events</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="events-area pt-100 pb-70">
        <div className="container d-flex">
          <div className="row justify-content-center">
            {data &&
              data?.map((item, key) => {
                const attachments = JSON.parse(item?.attachments || "[]");
                const isPDF =
                  attachments.length > 0 &&
                  attachments[0].toLowerCase().endsWith(".pdf");
                return (
                  <>
                    <div
                      className="col-lg-3 col-md-4"
                      style={{ cursor: "pointer" }}
                    >
                      <a
                        onClick={() => next(item?.id, item, item?.attachments)}
                      >
                        <div className="single-events-card style-4">
                          <div className="events-image">
                            <img
                              className="eventimage eventssize"
                              // src={
                              //   JSON.parse(item?.attachments || "[]")[0] ||
                              //   // "https://via.placeholder.com/150"
                              //   ""
                              // }

                              src={
                                isPDF
                                  ? "https://via.placeholder.com/150?text=PDF" // Placeholder for PDFs
                                  : attachments[0] || ""
                              }
                              alt="Event"
                            />
                            <div className="date">
                              <span>{item?.date.split("T")[0].slice(-2)}</span>
                              <p>{item?.month}</p>
                            </div>
                          </div>
                          <div className="events-content">
                            <h4>{item?.title}</h4>
                            {isPDF && (
                              <a
                                // href={attachments[0]}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="mt-4 text-primary"
                                // style={{
                                //   display: "block",
                                //   marginTop: "8px",
                                // }}
                              >
                                View PDF
                              </a>
                            )}
                          </div>
                        </div>
                      </a>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AyurvedaEvents;
