import React from "react";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ACADEMIC_CALENDAR_GET } from "../../Utils/InfoUploadingConst";

export default function AcademicCalenderHomeopathy({ setLoading }) {
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  // Function to convert class names like "1st Year", "2nd Year" to numbers for sorting
  const getClassNumber = (className) => {
    const classMap = {
      "1st Year": 1,
      "2nd Year": 2,
      "3rd Year": 3,
      // Add more mappings as needed
    };
    return classMap[className] || 0; // Default to 0 if class name not found
  };

  const getData = async () => {
    const config = {
      method: "get",
      url: ACADEMIC_CALENDAR_GET,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        const sortedData = res.data.data.sort((a, b) => {
          // Sort by session (assuming session is a number)
          if (a.session !== b.session) {
            return b.session - a.session; // Sort by session descending
          } else {
            // If sessions are equal, sort by classes
            const classesA = getClassNumber(a.classes);
            const classesB = getClassNumber(b.classes);
            return classesB - classesA;
          }
        });

        // sortedData.forEach((element) => {
        //   element.attachments = JSON.parse(element.attachments);
        // });

        console.log(sortedData);
        setData(sortedData);
      })
      .catch((err) => {
        // setLoading(0);
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  function formatSession(session) {
    // Assuming session is in the format "YYYY"
    const academicYear = `${session}-${parseInt(session, 10) + 1}`;
    return academicYear;
  }

  const sessionformat = {
    2025: "2025-26",
    2024: "2024-25",
    2023: "2023-24",
    2022: "2022-23",
    2021: "2021-22",
    2020: "2020-21",
    2019: "2019-20",
    2018: "2018-19",
    2017: "2017-18",
    2016: "2016-17",
    2015: "2015-16",
    2014: "2014-15",
    2013: "2013-14",
  };

  const sanitizeUrl = (url) => url.replace(/"/g, "");

  return (
    <div>
      <div className="container mt-5">
        <div className="row register" style={{ maxWidth: "100%" }}>
          <div className="col-md-12">
            <h3>ACADEMIC CALENDAR</h3>
            <p>Academic Calendar documents Links are below</p>
          </div>

          <div className="container">
            <div className="alumni-area mt-4">
              <div className="row">
                {data
                  .filter(
                    (item) =>
                      item.status == "ACTIVE" &&
                      item.college_id == 1111007 &&
                      item.type === "Academic Calendar"
                  )
                  .map((item, key) => (
                    <div className="col-md-4">
                      <a
                        // href={item?.attachments}
                        href={sanitizeUrl(item?.attachments)}
                        target="_blank"
                      >
                        <div className="single-alumoni-updates-card">
                          <div className=" d-flex flex-row flex-wrap align-items-center ">
                            <div className=" bg-danger text-center p-3 me-3">
                              <i
                                class="ri-file-download-line text-light "
                                style={{ fontSize: "20px" }}
                              ></i>
                            </div>
                            <div className="">
                              <h5>
                                {item?.classes} - (
                                {sessionformat[item?.session] || item?.session})
                              </h5>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
}
