import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Router/RouterConfig";
import { profileData } from "../../Pages/About/AutoritiesOfUniversity/ProfileData";
import "./Navbar.scss";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import {
  ACADEMIC_CALENDAR_GET,
  ADD_COMMITTEES,
  STAFF_DETAILS_NEW_GET,
} from "../../Utils/InfoUploadingConst";

function Navbar_law() {
  const navigate = useNavigate();

  const changeDir = (i) => {
    navigate(ROUTES.Profile, { state: { data: i } });
  };

  const [info, setInfo] = useState([]);
  const [data, setData] = useState([]);
  const [staffdata, setStaffData] = useState([]);

  const getData = async () => {
    const config = {
      method: "get",
      url: ADD_COMMITTEES,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        res.data.data.forEach((element) => {
          element.attachment = JSON.parse(element.attachment);
        });
        res.data.data.sort((a, b) => b.id - a.id);
        const activeData = res.data.data.filter(
          (element) =>
            element.status === "ACTIVE" && element.college_id === 1111002
        );
        console.log("activeData -", activeData);
        setInfo(activeData);
      })
      .catch((err) => {
        console.log(err);
      });

    const config2 = {
      method: "get",
      url: ACADEMIC_CALENDAR_GET,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config2)
      .then((res) => {
        // res.data.data.sort((a, b) => b.session - a.session);
        res.data.data.forEach((element) => {
          element.attachments = JSON.parse(element.attachments);
        });
        res.data.data.sort((a, b) => b.id - a.id);
        // console.log(res.data.data)
        const feeData = res.data.data.filter(
          (element) =>
            element.status === "ACTIVE" && element.college_id === 1111002
        );
        setData(feeData);
      })
      .catch((err) => {
        console.log(err);
      });

    const config3 = {
      method: "get",
      url: STAFF_DETAILS_NEW_GET,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config3)
      .then((res) => {
        // res.data.data.forEach((element) => {
        //   element.attachment = JSON.parse(element.attachment);
        // });
        res.data.data.sort((a, b) => b.id - a.id);
        // console.log(res.data.data)
        // const feeData = res.data.data.filter(
        //   (element) =>
        //     element.status === "ACTIVE" && element.college_id === 1111002
        // );
        const feeData = res.data.data.filter((element) => {
          // Check if attachment is in a JSON-stringified format and parse it
          if (
            typeof element.attachment === "string" &&
            element.attachment.startsWith('"') &&
            element.attachment.endsWith('"')
          ) {
            element.attachment = JSON.parse(element.attachment);
          }

          // Filter the data based on the status and college_id
          return element.status === "ACTIVE" && element.college_id === 1111002;
        });
        setStaffData(feeData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="Navbar-law">
      <div className="top-header-area">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-4 col-sm-4">
              <div className="header-right-content">
                <marquee className="scroll-text text-white">
                  <p className="text-white">
                    Toll free number to report ragging :{" "}
                    <a href="tel:1800 843 2900" className="text-light">
                      1800 843 2900
                    </a>{" "}
                  </p>
                </marquee>
              </div>
            </div>

            <div className="col-lg-8 col-sm-8">
              <div className="header-right-content">
                <div className="list">
                  <ul>
                    <li className="nav-item">
                      <Link to={"/law/Recruitment"} className="nav-link">
                        Recruitment
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://ums.swaminarayanuniversity.ac.in/"
                        target="_blank"
                      >
                        UMS Login
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
        <div className="navbar-area nav-bg-1">
          <div className="mobile-responsive-nav">
            <div className="container">
              <div className="mobile-responsive-menu">
                <div className="logo">
                  <a href="/law">
                    <img
                      src="/assets/images/white-logo.png"
                      className="main-logo"
                      alt="logo"
                    />
                    <img
                      src="/assets/images/white-logo.png"
                      className="white-logo"
                      alt="logo"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="desktop-nav">
            <div className="container-fluid">
              <nav className="navbar navbar-expand-md navbar-light">
                <a className="navbar-brand" href="/law">
                  <img
                    src="/assets/images/white-logo.png"
                    width="250px"
                    alt="logo"
                  />
                </a>
                <h5 class="text-left">Shree Swaminarayan Law College, Kalol</h5>
                <div
                  className="collapse navbar-collapse mean-menu"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                      <Link to={"/law"}>Home</Link>
                    </li>
                    <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="Overview"
                      >
                        Overview <span className="caret" />
                      </a>
                      <ul className="dropdown-menu nav-item">
                        <li className="nav-item">
                          <Link to={"/law/AboutUs"}>About SSVG</Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/law/FoundersMessage"}>
                            Founder Message
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/law/PrincipalMessage"}>
                            Principal Message
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/law/MissionVision"}>Mission Vision</Link>
                        </li>
                        <li className="dropdown nav-item">
                          <a
                            aria-expanded="true"
                            aria-haspopup="true"
                            role="button"
                            data-toggle="dropdown"
                            className="dropdown-toggle"
                            href="#"
                            title="Overview"
                          >
                            Office Bearers <span className="caret" />
                          </a>
                          <ul className="dropdown-menu nav-item">
                            <li className="nav-item">
                              {/* <a
                                onClick={() => {
                                  changeDir(profileData[0]);
                                }}
                                className="nav-link custom-div-link"
                              >
                                President
                              </a> */}
                              <Link to={"/law/President"} className="nav-link">
                                President
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to={"/law/VicePresidentMsg"}
                                className="nav-link"
                              >
                                Vice Presidents
                              </Link>
                            </li>
                            <li className="nav-item">
                              {/* <a
                                onClick={() => {
                                  changeDir(profileData[4]);
                                }}
                                className="nav-link"
                              >
                                Provost (Vice-Chancellor)
                              </a> */}
                              <Link to={"/law/Provost"} className="nav-link">
                                Provost (Vice-Chancellor)
                              </Link>
                            </li>
                            <li className="nav-item">
                              {/* <a
                                onClick={() => {
                                  changeDir(profileData[7]);
                                }}
                                className="nav-link custom-div-link"
                              >
                                Registrar
                              </a> */}
                              <Link
                                to="/law/Registrar-Profile"
                                className="nav-link"
                              >
                                Registrar
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="College"
                      >
                        College <span className="caret" />
                      </a>
                      <ul className="dropdown-menu nav-item">
                        <li className="nav-item">
                          <Link to={"/law/AboutCollege"}>About College</Link>
                        </li>

                        {/* <li className="nav-item">
                          <Link to={"/law/Courses"}>Courses</Link>
                        </li> */}

                        <li className="nav-item">
                          <Link to="/law/Permission" title="Permission">
                            Permission
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/law/Affiliation" title="Affiliation">
                            Affiliation
                          </Link>
                        </li>
                        {/* <li className="nav-item">
              <a href="#" title="Facilities">
                Facilities
              </a>
            </li>
         <li className="nav-item nav-item">
              <a href="#" title="Departments">
                Departments
              </a>
            </li> */}
                      </ul>
                    </li>
                    <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="Academics"
                      >
                        Academics <span className="caret" />
                      </a>
                      <ul className="dropdown-menu">
                        {/* <li className="nav-item">
                          <Link to={"/law/Achievements"}>Achievements</Link>
                        </li> */}
                        {/* <li className="nav-item">
                          <Link to={"/law/Achievements-Awards-Medals"}>
                            Achievements
                          </Link>
                        </li> */}
                        <li className="nav-item">
                          <Link to={"/law/Eligibility"}>
                            {/* Eligibility */}
                            Admission details with Eligibility Criteria
                          </Link>
                        </li>

                        {data?.filter((d) => d.type === "Fee Structure")
                          .length !== 0 ? (
                          data
                            ?.filter((d) => d.type === "Fee Structure")
                            ?.map((item, index) => (
                              <li className="nav-item" key={index}>
                                <a
                                  href={item?.attachments}
                                  target="_blank"
                                  className="nav-link"
                                >
                                  Fee Structure
                                </a>
                              </li>
                            ))
                        ) : (
                          <li className="nav-item">
                            <a href="#" target="_blank" className="nav-link">
                              Fee Structure
                            </a>
                          </li>
                        )}

                        {/* <li className="nav-item">
                          <Link to={"/law/FeeStructure"}> Fee Structure</Link>
                        </li> */}

                        {data?.filter((d) => d.type === "Courses Details")
                          .length !== 0 ? (
                          data
                            ?.filter((d) => d.type === "Courses Details")
                            ?.map((item, index) => (
                              <li className="nav-item" key={index}>
                                <a
                                  href={item?.attachments}
                                  target="_blank"
                                  className="nav-link"
                                >
                                  Courses
                                </a>
                              </li>
                            ))
                        ) : (
                          <li className="nav-item">
                            <a href="#" target="_blank" className="nav-link">
                              Courses
                            </a>
                          </li>
                        )}

                        {/* <li className="nav-item">
                          <Link to={"/law/AcademicEvents"}> Academic Events </Link>
                        </li> */}

                        {/* <li className="nav-item">
                          <a href="#" className="nav-link dropdown-toggle">
                            Committees
                          </a>
                          <ul className="dropdown-menu">
                            {info.filter((d) => d.type === "College Council")
                              .length !== 0 ? (
                              info
                                .filter((d) => d.type === "College Council")
                                .map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      College Council
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  College Council
                                </a>
                              </li>
                            )}

                            {info.filter(
                              (d) => d.type === "Curriculum Committee"
                            ).length !== 0 ? (
                              info
                                .filter(
                                  (d) => d.type === "Curriculum Committee"
                                )
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      Curriculum Committee
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  Curriculum Committee
                                </a>
                              </li>
                            )}

                            {info.filter(
                              (d) => d.type === "Internal Complaint Committee"
                            ).length !== 0 ? (
                              info
                                .filter(
                                  (d) =>
                                    d.type === "Internal Complaint Committee"
                                )
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      Internal Complaint Committee
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  Internal Complaint Committee
                                </a>
                              </li>
                            )}

                            {info.filter(
                              (d) =>
                                d.type ===
                                "Hospital Infection Control Committee"
                            ).length !== 0 ? (
                              info
                                .filter(
                                  (d) =>
                                    d.type ===
                                    "Hospital Infection Control Committee"
                                )
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      Hospital Infection Control Committee
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  Hospital Infection Control Committee
                                </a>
                              </li>
                            )}

                            {info.filter(
                              (d) => d.type === "Anti-Ragging Committee"
                            ).length !== 0 ? (
                              info
                                .filter(
                                  (d) => d.type === "Anti-Ragging Committee"
                                )
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      Anti-Ragging Committee
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  Anti-Ragging Committee
                                </a>
                              </li>
                            )}

                            {info.filter((d) => d.type === "Ethics Committee")
                              .length !== 0 ? (
                              info
                                .filter((d) => d.type === "Ethics Committee")
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      Ethics Committee
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  Ethics Committee
                                </a>
                              </li>
                            )}

                            {info.filter(
                              (d) => d.type === "Pharmacovigilance Committee"
                            ).length !== 0 ? (
                              info
                                .filter(
                                  (d) =>
                                    d.type === "Pharmacovigilance Committee"
                                )
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      Pharmacovigilance Committee
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  Pharmacovigilance Committee
                                </a>
                              </li>
                            )}

                            {info.filter(
                              (d) => d.type === "Scientific review Committee"
                            ).length !== 0 ? (
                              info
                                .filter(
                                  (d) =>
                                    d.type === "Scientific review Committee"
                                )
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      Scientific review Committee
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  Scientific review Committee
                                </a>
                              </li>
                            )}
                          </ul>
                        </li> */}

                        <li className="nav-item">
                          {/* <a
                            href="/assets/law/documents/academic-calender.pdf"
                            target="_blank"
                          >
                            Academic Calender 23-24
                          </a> */}
                          <Link to="/law/AcademicCalendar">
                            Academic Calendar
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/law/Publications">Publications</Link>
                        </li>

                        <li className="nav-item">
                          <Link to={"/law/Syllabus"}>Syllabus</Link>
                        </li>
                        {/* <li className="nav-item">
                          <Link to={"/law/studentList"}>Students Lists</Link>
                        </li> */}
                        <li className="nav-item">
                          <Link to={"/law/resultsList"}>Students Results</Link>
                        </li>
                      </ul>
                    </li>
                    {/* <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="Academics"
                      >
                        Law <span className="caret" />
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <a
                            href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/CBDC+First+BHMS/Introduction+to+CBDC.pdf"
                            target="_blank"
                          >
                            Introduction
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/CBDC+First+BHMS/Letter+reg.+formation+of+college+level+Competency+Based+Dynamic+Curriculum+(CBDC)+implementation+Committee.pdf"
                            target="_blank"
                          >
                            Reg. Letter
                          </a>
                        </li>
                        
                      </ul>
                    </li> */}
                    <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="Staff"
                      >
                        Staff <span className="caret" />
                      </a>
                      <ul className="dropdown-menu">
                        {/* <li className="nav-item">
                          <Link to={"/law/PrincipalMessage"}>Principal</Link>
                        </li> */}
                        {/* <li className="nav-item">
                          <Link to="/law/TeachingStaff"> Teaching Staff</Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/law/non-teachingStaff">
                            {" "}
                            Non-Teaching Staff
                          </Link>
                        </li> */}

                        {staffdata?.filter(
                          (d) => d.emp_category === "TEACHING STAFF"
                        ).length !== 0 ? (
                          staffdata
                            ?.filter((d) => d.emp_category === "TEACHING STAFF")
                            ?.map((item, index) => (
                              <li className="nav-item" key={index}>
                                <a
                                  href={item?.attachment}
                                  target="_blank"
                                  className="nav-link"
                                >
                                  Teaching Staff
                                </a>
                              </li>
                            ))
                        ) : (
                          <li className="nav-item">
                            <a
                              href="/law/"
                              target="_blank"
                              className="nav-link"
                            >
                              Teaching Staff
                            </a>
                          </li>
                        )}

                        {staffdata?.filter(
                          (d) => d.emp_category === "NON-TEACHING STAFF"
                        ).length !== 0 ? (
                          staffdata
                            ?.filter(
                              (d) => d.emp_category === "NON-TEACHING STAFF"
                            )
                            ?.map((item, index) => (
                              <li className="nav-item" key={index}>
                                <a
                                  href={item?.attachment}
                                  target="_blank"
                                  className="nav-link"
                                >
                                  Non-Teaching Staff
                                </a>
                              </li>
                            ))
                        ) : (
                          <li className="nav-item">
                            <a
                              href="/law/"
                              target="_blank"
                              className="nav-link"
                            >
                              Non-Teaching Staff
                            </a>
                          </li>
                        )}
                      </ul>
                    </li>
                    <li className="nav-item">
                      <Link to={"/law/events"}> Events </Link>
                    </li>
                    <li className="nav-item">
                      <Link to={"/law/ContactUs"}>Contact Us</Link>
                    </li>
                    <li className="nav-item">
                      <a href="#" title="Member Menu">
                        <i className="glyphicon glyphicon-user" />
                      </a>
                    </li>{" "}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Navbar_law;
